import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Etsy from "../components/etsy"
import sizeMe from "react-sizeme"

const Shop = ({ location, size }) => {
  return (
    <Layout location={location}>
      <SEO title="Shop" />
      <Etsy size={size} />
    </Layout>
  )
}

export default sizeMe()(Shop)
